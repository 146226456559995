import { render, staticRenderFns } from "./Relatorios.vue?vue&type=template&id=7bebe784&scoped=true&"
import script from "./relatorios.ts?vue&type=script&lang=ts&"
export * from "./relatorios.ts?vue&type=script&lang=ts&"
import style0 from "./Relatorios.vue?vue&type=style&index=0&id=7bebe784&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bebe784",
  null
  
)

export default component.exports